<template>
<div>
    <AtpDetail />
</div>
</template>

<script>
import AtpDetail from '../components/dashboard/AtpDetail.vue'
export default {
    components: {
        AtpDetail
    }
}
</script>

