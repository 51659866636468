<template>
<div>
    <Wrapper>
        <Sidebar slot="sidebar" />
        <Navbar slot="navbar" />
        <Footer slot="footer" />
        <div slot="content">
            <!-- <input type="text" v-model="filters.name.value"> -->
            <div class="container-fluid">

                <div class="pre-loader text-center" v-if="preLoader">
                    <span class="loader"></span>
                </div>

                <template v-if="searchString == ''">
                    <template v-if="productDetailComponent">
                        <productDetail />
                    </template>

                    <template v-if="windowWidth < 501">
                        <div class="pt-2 animated fadeIn">
                            <div style="margin-bottom:20px">

                                <h4 class="mb-2">
                                    <img src="../../assets/images/chev.png" alt="" height="18" @click="$router.go(-1)" class="pr-4 pb-1">

                                    {{atpDetail.name}}</h4>

                                <div class="product-img-div" :style="{ backgroundImage: 'url(' +serVerImage+atpDetail.atp_image + ')' }">

                                </div>
                                <h6 class="underline"><b>{{atpDetail.atp}}</b></h6>
                                <p class="pt-2" v-html="atpDetail.detail"></p>
                            </div>

                        </div>
                        <hr>
                        <h4 class="mb-2"> <b> {{tran.product}}s </b> </h4>
                        <div class="mobile-product-div">
                            <div class="mb-2" v-for="(prod, index) in atpProducts" :key="index">

                                <template v-if="prod.category == 'upcoming'">
                                    <div class="product-img-div" :style="{ backgroundImage: 'url(' + serVerImage+prod.product_image + ')' }" @click.prevent="showMobileComponentWithUrl(prod, 'upcoming')">
                                    </div>

                                </template>

                                <template v-else>
                                    <div class="product-img-div" :style="{ backgroundImage: 'url(' + serVerImage+prod.product_image + ')' }" @click.prevent="showMobileComponentWithUrl(prod, 'notupcoming')">
                                    </div>

                                </template>

                                <div>
                                    <h5>{{prod.name}}</h5>
                                
                                    
                                    <template v-if="prod.units_available < 1 && prod.settlement_status !== 'settled'  || prod.resell_units_available < 1  && prod.settlement_status !== 'settled'">
                                        <span class="sold-out-mob" style="font-size:12px">SOLD OUT</span>

                                    </template>

                                    <template v-if="prod.settlement_status == 'settled'">
                                        <span class="paid-out-mob" style="font-size:12px">PAID OUT</span>
                                    </template>

                                    <template  v-if="prod.units_available > 0 && prod.settlement_status !== 'settled' || prod.resell_units_available > 0  && prod.settlement_status !== 'settled'">

                                         <span><b> {{prod.roi}}</b>%</span> - <span><span v-if="prod.numOfDays <=13">
                                                            {{prod.numOfDays + ' days'}}
                                                        </span>
                                                         <span v-if="prod.numOfDays >=  14 && prod.numOfDays <=  29">
                                                            {{floorNumb(prod.numOfDays) +  ' weeks'}}
                                                        </span>
                                                          <span v-if=" prod.numOfDays >  29">
                                                            {{prod.maturityInMonths}}
                                                        </span></span>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>

                    <div style="display:flex; min-height:20vh;" class="pt-4" v-if="windowWidth > 501">

                        <div class="mr-4" style="display:flex;flex-basis:20%; flex-grow:2 !important;
                     justify-content:center; min-width:100px">
                            <img :src="serVerImage+atpDetail.atp_image" height="50">
                        </div>
                            <div style="flex-basis:50%; flex-grow:7 !important; justify-content:space-between" class="mr-4">
                                <h4 class="mb-4 head-font">{{atpDetail.name}}</h4>

                                   <div v-html="atpDetail.detail"></div>


                                <!-- <div class="align-items-bottom " style=" flex-grow:0 !important;">
                                    <p>Insured by</p>
                                    <p><b>{{atpProducts[0].insurer_name}}</b></p>
                                </div> -->
                            </div>
                        </div>

                        <div v-if="windowWidth > 501" class="product-cover mt-3">
                            <h4 class="mb-2">{{tran.product}}s</h4>
                            <div v-for="(prod, index) in atpProducts" :key="index" style="display:inline; border-radius:10px">
                                <div class="item" @click="getProductObject(prod, $event)">
                                    <div class="img-div-1" :class="{'showHideDiv': productSelected == prod.id}" style="position: absolute; z-index: 90;" @click.prevent="closeAllDivs($event)">
                                    </div>
                              <div class="img-div" :style="{ backgroundImage: 'url(' +serVerImage+prod.product_image + ')' }">
                                    <p class="scrollDiv"></p>

                                
                                    <template v-if="prod.units_available > 0 && prod.settlement_status !== 'settled' || prod.resell_units_available > 0 && prod.settlement_status !== 'settled'">

                                        <p class="text-right" style="text-decoration:underline" v-if="prod.category  !== 'resell'">{{prod.units_available}} {{tran.units_available}}</p>
                                        <p class="text-right" style="text-decoration:underline" v-else>{{prod.resell_units_available ||prod.units_available }} {{tran.units_available}}</p>

                                    </template>

                                      <template v-if="prod.units_available < 1 && prod.settlement_status !== 'settled'">
                                                                <div class="sold-out">
                                                                    <p>SOLD OUT</p>
                                                                </div>
                                                            </template>
                                                            <template v-if="prod.settlement_status == 'settled'">
                                                                <div class="paid-out"  >
                                                                    <p>PAID OUT</p>
                                                                </div>
                                                            </template>
                                    <div class="atp-preview">
                                        <div>
                                            <h6>
                                                <b>{{prod.name}}</b>
                                            </h6>
                                            <p>{{prod.atp}}</p>
                                        </div>
                                        <!-- <div class="atp-roi">
                                            <p v-if="currentCategory == 'primary' || currentCategory == 'upcoming'">{{tran.up_to}} {{prod.roi}}%</p>
                                            <p v-else-if="currentCategory == 'secondary'"> {{prod.roi}}%</p>
                                            <p v-else>{{tran.up_to}} {{prod.resell_roi}}%</p>
                                            <p>{{prod.matures_at}}</p>
                                        </div> -->

                                            <div class="atp-roi">
                                                    <p>  <span v-if="prod.category == 'primary' || prod.category == 'upcoming'">{{tran.up_to}}</span><span v-if="prod.resell_units_available < 1 || prod.units_available < 1"> {{prod.roi_opened_with}} % </span> <span v-else> {{prod.roi}}% </span></p>
                                                    
                                                  
                                                <p v-if="prod.resell_units_available < 1  || prod.units_available < 1">{{prod.product_opened_with}}</p>
                                                    <p v-else> <span v-if="prod.numOfDays <=13">
                                                            {{prod.numOfDays + ' days'}}
                                                        </span>
                                                         <span v-if="prod.numOfDays >=  14 && prod.numOfDays <=  29">
                                                            {{floorNumb(prod.numOfDays) +  ' weeks'}}
                                                        </span>
                                                          <span v-if=" prod.numOfDays >  29">
                                                            {{prod.maturityInMonths}}
                                                        </span></p>
                                                </div>
                                          </div>
                                     </div>

                                    <div style="position:relative;text-align:center; right:12px">
                                        <img src="../../assets/images/chev-up.png"  class="fa-angle-down animated slideInDown mb-4"  style="margin-top:0.8rem!important" height="30">
                                        </div>
                                    </div>





                                    

                                    <div class="info mb-4">
                                            <div class="investment-details">
                                                <div class="product-detail mr-4">
                                                    <div>
                                                        <h3>{{prod.name}} <span v-if="prod.category == 'upcoming'">(Coming Soon)</span></h3>
                                                     
                                                        <div v-html="prod.details"></div>

                                                    </div>
                                                   
                                                </div>

                                                <div class="cart-point mt-4">
                                                    <div style="" class="close-btn">
                                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:250px; justify-content:space-between; padding:0px 10px" class="mr-4">
                                                      <img :src="imgUrlFromServer+prod.atp_image" loading="lazy" height="20">


                                                        <p style="color:#4F4F4F">{{prod.atp}}</p>
 <img src="../../assets/images/info.png" loading="lazy" height="20">
                                                    </div>
                                                    <div style="min-height:30px; background:#fff; border-radius:20px; display:flex; align-items:center; max-width:250px; justify-content:space-between; padding:0px 10px" class="mr-4">
 <!-- <img :src="imgUrlFromServer+prod.insurer_image" loading="lazy" height="20"> -->


                                                        <p style="color:#4F4F4F">{{prod.insurer_name}}</p>
 <img src="../../assets/images/info.png" loading="lazy" height="20">
                                                    </div>
                                                        <img src="../../assets/images/close-exit.png" loading="lazy" height="25" style="cursor:pointer" class="close-info-div" @click.prevent="closeInfoDiv($event)">
                                                   </div>
                                         <div class="crt-div mt-4 mb-4">
                                                     <div style="display:flex;" >
                                                         <img src="../../assets/images/ui1.png" loading="lazy" height="15" class="mr-2">
                                                         <p v-if="prod.units_available < 1" class="user-roi  fw-600"> {{formatNumber(prod.roi_opened_with)}} % Interest</p>
                                          <p class="user-roi fw-600" v-else> {{formatNumber(prod.roi)}}% Interest</p>

                                               <div style="display: flex;
    justify-content: flex-end;">


       <input class="prod-url" type="hidden" :value="productUrl+'/market/'+`${prod.category}`+'/'+`${prod.urlName}`+'/'+`${prod.id}`" style="position:absolute">
                                                      
                                                        <img src="../../assets/images/share-new.png" loading="lazy" height="30" style="cursor:pointer" @click.prevent="copyProductUrl($event)" :content="tran.copied_to_clipboard" v-tippy="{trigger : 'click', hideOnClick : true, sticky : false}">
                                                                </div>
                                         
                                                    </div>

                                             <div style="display:flex;">
                                               <p class="text-left fw-600" v-if="prod.units_available < 1">
                                                    <img src="../../assets/images/ui2.png" loading="lazy" height="15" class="mr-2">{{prod.product_opened_with}}</p>
                                                                <p class="text-left fw-600" v-else>    <img src="../../assets/images/ui2.png" loading="lazy" height="15" class="mr-2"> <span v-if="prod.numOfDays <=13">
                                                            {{prod.numOfDays + ' days'}}
                                                        </span>
                                                         <span v-if="prod.numOfDays >=  14 && prod.numOfDays <=  29">
                                                            {{floorNumb(prod.numOfDays) +  ' weeks'}}
                                                        </span>
                                                          <span v-if=" prod.numOfDays >  29">
                                                            {{prod.maturityInMonths}}
                                                        </span></p>
                                                 
<!-- 
                                              <p class="text-right fw-600"> <span>&#8358;</span>{{formatAmount(prod.cost_per_unit)}}</p> -->
                                                            </div>

                                          
                                                <div style="display:flex;">
                                                     <img src="../../assets/images/ui3.png" loading="lazy" height="15" class="mr-2">
                                                <p class="text-left fw-600"> <span>&#8358;</span>{{formatAmount(prod.cost_per_unit)}} per unit</p> 

                                            <!-- <p class="text-right fw-600" v-if="prod.units_available < 1">{{prod.product_opened_with}}</p>
                                                                <p class="text-right fw-600" v-else>{{prod.matures_at}}</p> -->

                                                            </div>
                                                            <div style="display:flex;" >

                                                                  <img src="../../assets/images/ui4.png" loading="lazy" height="15" class="mr-2">
                                                            
                                                                <p><span style="color:#828282">Opens On:</span>
                                                                <b> {{prod.opens_at | formatDate }}</b>
                                                                </p>

                                                            </div>
                                                            <div style="display:flex;" >

                                                                  <img src="../../assets/images/ui4.png" loading="lazy" height="15" class="mr-2">
                                                            
                                                                <p><span style="color:#828282">Matures On:</span>
                                                                <b> {{prod.marturity_date | formatDate }}</b>
                                                                </p>
                                                              

                                                            </div>
                                                        </div>

                                                        <div class="add-units" :class="{'fade-elem': prod.units_available == 0,  'hide-elem':prod.units_available < 1 && prod.settlement_status !== 'settled'  ||prod.settlement_status == 'settled'}">
                                                                 <div class="display-div pt-3" style=" padding:6px 15px; border-bottom: 1px solid #F2F2F2;">
                                                                <div class="text-left" >
                                                            <p class="pb-1" style="color:#828282
">Cost (Multiples of ₦5,000):</p>
                                                                   <span style="color:#0094FF">&#8358;</span>
                                                                    <span class="cost-unit text-center" style="color:#0094FF">   {{formatAmount(prod.user_cost)}}</span>

                                                                </div>
                                                                <div class="text-left" style="color:#58B46F">
                                                                        <p class="pb-1" style="color:#828282
">ROI (Return on Investment):</p>
                                                                    <span>&#8358;</span>
                                                                    <span class="user-amount text-center">{{formatNumber(prod.user_roi)}}</span>

                                                                </div>

                                                            </div>
                                                            <h6 class="pt-4 pb-2 text-left"><b>Add Units</b> <span style="color:#828282
">(One unit =  ₦5,000)</span></h6>

    <div style="padding:10px; display:flex; justify-content:space-between">

                                                            <div style="padding:0px 15px;display:flex; align-items:center; border: 0.5px solid #33A9FF; border-radius:5px; background:#F5F5F5; flex-basis:60%" class="mr-2" >
                                                        
                                                                <i class="fa fa-minus text-left" @click="getUserRoi($event, prod, 'minus')"  :class="{'fade-elem':prod.defaultUnitSelected < 2 }" style="flex-basis:10%"></i>

                                                                <input type="text" class="cart-input text-center" @keyup="checkUnitValue( $event, prod)" onfocus="this.setSelectionRange(this.value.length,this.value.length);" :value="prod.defaultUnitSelected" style="flex-basis:80%; background:transparent">

                                                                <i class="fa fa-plus text-right" @click="getUserRoi($event, prod, 'add')" :class="{'fade-elem': prod.defaultUnitSelected == prod.units_available }" style="flex-basis:10%"></i>

                                                            </div>
                                                       <button @click.prevent="addToCart(prod,$event)" class="cart-btn" :class="{'fade-elem': loader }" v-if="token !== null" style="flex-basis:30%">
                                            {{loader ? '' :  tran.add_tocart}}
                                            <span class="loader" v-if="loader"></span>
                                          </button>
                                                            <button class="cart-btn"  @click="sendGuestTosignUp" style="flex-basis:30%" v-else>
                                            {{  tran.sign_up_to_purchase}}
                                            <span class="loader" v-if="loader"></span>
                                          </button>    
    </div>
                                                       
                                                         
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                      <div style="clear: left;border-top-left-radius: 20px;
  border-top-right-radius: 20px;"></div>
                                </div>
                </template>
                <template v-if="searchString !== ''">
                    <searchProduct />
                </template>
            </div>
        </div>
    </Wrapper>
             
</div>
</template>

<script>
import Navbar from '../partials/Navbar'
import Sidebar from '../partials/Sidebar'
import Footer from '../partials/Footer'
import Wrapper from '../partials/Wrapper'
import trans from "../../../translations"
import searchProduct from "./SearchProduct";
import {
    mapState
} from "vuex";
import productDetail from "../partials/MobileProductDetail";
import {
    commonJs
} from './mixins/commonJs.js';
import { setTimeout } from 'timers';

export default {
    mixins: [commonJs],
    data() {

        return {
            id: this.$route.params.id,
            productSelected: this.$route.params.id,
        }
    },
    methods: {

        filteredData(data) {
            this.$helpers.log(this.minInterest);
            let vm = this;
            return _.filter(data, function (data) {
                if (
                    (vm.startDate == "" || vm.endDate == "") &&
                    (vm.minInterest == "" || vm.maxInterest == "")
                ) {
                    return true;
                } else if (vm.startDate && vm.endDate) {
                    let date = data.tenor;
                    return date >= vm.startDate && date <= vm.endDate;
                } else if (vm.minInterest && vm.maxInterest) {
                    let interestInLoop = data.interest;
                    return (
                        interestInLoop >= vm.minInterest && interestInLoop <= vm.maxInterest
                    );
                }
            });
        },

        sliceData(data) {
            return data.slice(0, 4)
        }
    },

    components: {
        Navbar,
        Footer,
        Sidebar,
        Wrapper,
        productDetail,
        searchProduct

    },
    computed: {
        ...mapState({
            atpDetail: state => state.market.atpDetail,
            atpProducts: state => state.market.atpProducts,
            productsForSale: state => state.market.productsForSale,
            cartItems: state => state.market.cartItems,
            productObject: state => state.market.productObject,
            productsForMobile: state => state.market.productsForMobile,
            isComingSoon: state => state.market.isComingSoon,
                   currentCategory: state => state.market.currentCategory,
            productDetailComponent: state => state.market.productDetailComponent
        }),

        cartInputValue() {
            return this.cartInput;
        }
    },
    mounted() {
        this.tran = trans;

        let heading = {

            main: this.tran.market,
            sub: this.atpDetail.name
        }

        this.$nextTick(() => {
            this.$store.commit('market/savePageHeading', heading)
        });

        this.$store.commit("market/hideMobileProductDetailComponent")
            this.$store.dispatch("market/getAllProducts")


                this.$store.dispatch('market/viewAtp', this.$route.params.id);

    }

}
</script>

<style scoped>
.fa-angle-down {
    position: absolute;
    display: none;
    color: #000;
    top: 2px;
    font-size: 38px;
    z-index: -5;
    text-align: center;
}
</style>
